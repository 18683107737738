import React, { useEffect, useState } from 'react';

interface CardData {
    id: string;
    tagline?: string;
    className?: string;
    heading: string;
    headingClassName?: string;
    description?: string;
    descriptionClassName?: string;
    iconClassName?: string;
    link: string;
    cardId: string;
    carouselClass?: string;
    carouselGroups?: CarouselGroup[];
}

interface CarouselImage {
    imgSrc: string;
    alt: string;
    class: string;
}

interface CarouselGroup {
    groupClass: string;
    images: CarouselImage[];
}

interface TabData {
    id: string;
    tabId: string;
    icon: string;
    title: string;
    description?: string;
    cards: CardData[];
}

interface InsightsSectionProps {
    tagline: string;
    heading: string;
    tabs: TabData[];
}

const InsightsSection: React.FC<InsightsSectionProps> = ({ tagline, heading, tabs }) => {
    const [activeTabId, setActiveTabId] = useState(tabs?.[0]?.tabId);
    const [style, setStyle] = useState<React.CSSProperties>({ opacity: 0 });
    const [progress, setProgress] = useState(0);

    const handleTabClick = (tabId: string) => {
        setActiveTabId(tabId);
        setStyle({ opacity: 0 }); // Önce opaklık 0 olacak (gizlenecek)
        setProgress(0);

        // Yeni tab açıldığında stil değişimi animasyonlu olacak
        setTimeout(() => {
            setStyle({
                opacity: 1,
                transition: 'all 200ms ease-in-out',
            });
        }, 100);

        startProgress();
    };

    const startProgress = () => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 1; // %1 artırıyoruz
                } else {
                    clearInterval(interval); // %100'e ulaştığında interval'ı durduruyoruz
                    return 100;
                }
            });
        }, 100);
    }

    useEffect(() => {
        // İlk yüklemede mevcut sekmeye animasyon uygula
        setTimeout(() => {
            setStyle({
                opacity: 1,
                transition: 'all 200ms ease-in-out',
            });
        }, 100);

        startProgress();
    }, []);


    return (
        <section className="section-regular background-cream no-padding-mobile">
            <div className="full-width-container">
                <div className="middle-heading-set">
                    <div className="tagline-heading">{tagline}</div>
                    <div className="heading-set-3 middle">
                        <h2 className="h2-heading middle">{heading}</h2>
                    </div>
                </div>
            </div>
            <div className="full-width-tab-holder">
                <div className="w-tabs">
                    <div className="content-tabs-menu w-tab-menu">
                        {tabs.map((tab) => (
                            <a
                                key={tab.id}
                                data-w-tab={tab.tabId}
                                className={`content-tab-link-2 w-inline-block w-tab-link ${activeTabId === tab.tabId ? 'w--current' : ''}`}
                                onClick={() => handleTabClick(tab.tabId)}
                            >
                                <div className="tab-link-loader-2">
                                    <div style={activeTabId === tab.tabId ? {
                                        width: `${progress}%`, // Yükleme çubuğunun genişliğini buradan ayarlıyoruz
                                        height: '8px',
                                        transition: 'width 100ms linear', // Her artış için geçiş animasyonu
                                        willChange: 'width', // Yüksek performans için animasyon optimizasyonu
                                    } : { width: 0 }} className="loader-bar-2"></div>
                                </div>
                                <div className="limited-heading-set">
                                    <div className="icon sharp">
                                        <div className="fa-solid-span turqoise">{tab.icon}</div>
                                    </div>
                                    <h3 className="h5-heading">{tab.title}</h3>
                                    <div className="paragraph-regular mobile-none">{tab.description}</div>
                                </div>
                            </a>
                        ))}
                    </div>
                    <div className="w-tab-content">
                        {tabs.map((tab) => (
                            <div
                                key={tab.id}
                                data-w-tab={tab.tabId}
                                className={`content-tab-pane w-tab-pane ${activeTabId === tab.tabId ? 'w--tab-active' : ''}`}
                                style={activeTabId === tab.tabId ? style : { opacity: 0 }}

                            >
                                <div className="content-pane-wrapper">
                                    <div className="w-layout-grid bento-grid _6-columns">
                                        {tab.cards.map((card) => (
                                            <a key={card.id} id={card.id} href={card.link} className={card.className ? card.className : "bento-card w-inline-block"} >
                                                <div className={`bento-card-header ${(!card.description) ? "no-gap" : ""}`}>
                                                    <div className={`heading-wrap ${(!card.description) ? "m0-0" : ""}`}>
                                                        <div className="limited-heading-set">
                                                            {card.tagline && <div className="tagline-heading">{card.tagline}</div>}
                                                            <h4 className={card.headingClassName ? card.headingClassName : "h4-heading"}>{card.heading}</h4>
                                                            {card.description && <p className={card.descriptionClassName ? card.descriptionClassName : "description"}>{card.description}</p>}
                                                        </div>
                                                        <div className="icon">
                                                            <div className={card.iconClassName ? card.iconClassName : "fa-solid-span"}></div>
                                                        </div>
                                                    </div>
                                                    {/* Render carousel if available */}
                                                    {card.carouselGroups && (
                                                        <div className="content-carousel-wrap">
                                                            <div className={card.carouselClass}>
                                                                {card.carouselGroups.map(
                                                                    (group, groupIndex) => (
                                                                        <div
                                                                            key={groupIndex}
                                                                            className={
                                                                                group.groupClass
                                                                            }
                                                                        >
                                                                            {group?.images?.map(
                                                                                (img, imgIndex) => (
                                                                                    <div
                                                                                        key={
                                                                                            imgIndex
                                                                                        }
                                                                                        className="content-carousel-logo"
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                img.imgSrc
                                                                                            }
                                                                                            alt={
                                                                                                img.alt
                                                                                            }
                                                                                            className={
                                                                                                img.class
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                            <div className="content-carousel-overlay"></div>
                                                        </div>
                                                    )}
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section >
    );
};

export default InsightsSection;
