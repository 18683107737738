import React, { useEffect, useRef, useState } from 'react';
import ContentModal from '../ContentModal';
import Timeline from '../Timeline';

interface FdiSlideData {
    id: string;
    frontImage: string;
    frontAlt: string;
    frontNumber: string;
    frontTitle: string;
    backTitle: string;
    backBoldText: string;
    backImage: string;
    backRegularText: string;
    items:string[];
}

interface QualityFdiSliderProps {
    slides: FdiSlideData[];
    tagline: string;
    heading: string;
}

const QualityFdiSlider: React.FC<QualityFdiSliderProps> = ({ slides, tagline, heading }) => {
    const [flippedCards, setFlippedCards] = useState<{ [key: string]: boolean }>({});
    const [currentSlide, setCurrentSlide] = useState(0);
    const [selectedItem, setSelectedItem] = useState<FdiSlideData>();
    const [showContentModal, setShowContentModal] = useState<boolean>(false);
    const [offsetX, setOffsetX] = useState(0);
    const totalSlides = slides.length;
    const [slideWidth, setSlideWidth] = useState(0);
    const slideRef = useRef<HTMLDivElement | null>(null);
    const touchStartX = useRef(0);
    const isDragging = useRef(false);

    const handleFlip = (id: string) => {
        setFlippedCards((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleNextSlide = () => {
        if (currentSlide < totalSlides - 1) {
            setCurrentSlide((prevSlide) => prevSlide + 1);
            setOffsetX(0);
        }
    };

    const handlePrevSlide = () => {
        if (currentSlide > 0) {
            setCurrentSlide((prevSlide) => prevSlide - 1);
            setOffsetX(0);
        }
    };

    const updateSlideWidth = () => {
        if (slideRef.current) {
            const width = slideRef.current.clientWidth;
            const padding = window.innerWidth >= 768 ? 32 : 16;
            setSlideWidth(width + padding);
        }
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        const touch = e.touches[0];
        if (touch) {
            touchStartX.current = touch.clientX;
            isDragging.current = true;
        }
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging.current) return;
        const touch = e.touches[0];
        if (touch) {
            const touchMoveX = touch.clientX;
            const deltaX = touchMoveX - touchStartX.current;

            // İlk slayttayken sola, son slayttayken sağa fazla kaymayı sınırlıyoruz
            if ((currentSlide === 0 && deltaX > 0) || (currentSlide === totalSlides - 1 && deltaX < 0)) {
                setOffsetX(deltaX / 4); // Sınırlı kaydırma efekti için direnç ekliyoruz
            } else {
                setOffsetX(deltaX);
            }
        }
    };

    const handleTouchEnd = () => {
        isDragging.current = false;

        if (offsetX < -slideWidth / 4 && currentSlide < totalSlides - 1) {
            handleNextSlide();
        } else if (offsetX > slideWidth / 4 && currentSlide > 0) {
            handlePrevSlide();
        } else {
            setOffsetX(0);
        }
    };

    useEffect(() => {
        updateSlideWidth();
        window.addEventListener('resize', updateSlideWidth);

        return () => {
            window.removeEventListener('resize', updateSlideWidth);
        };
    }, []);

    return (
        <section id="quality-fdi" className="fdi-slider-section">
            <div className="full-width-container">
                <div className="middle-heading-set">
                    <div className="tagline-heading">{tagline}</div>
                    <div className="heading-set-3">
                        <h2 className="h2-heading middle">{heading}</h2>
                    </div>
                </div>
            </div>
            <div
                className="team-slider w-slider"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className="team-slider-mask w-slider-mask">
                    {slides.map((slide, index) => {
                        const isFlipped = flippedCards[slide.id] || false;
                        const isActive = index === currentSlide;
                        return (
                            <div
                                key={slide.id}
                                className={`team-slide w-slide ${isActive ? 'active' : ''}`}
                                style={{
                                    transform: `translateX(${-currentSlide * slideWidth + offsetX}px)`,
                                    transition: isDragging.current ? 'none' : 'transform 500ms ease',
                                    opacity: isActive ? 1 : 0.5,
                                }}
                                ref={index === 0 ? slideRef : null}
                            >
                                <div
                                    className={`flipcard-wrapper ${isFlipped ? 'flipped' : ''}`}
                                >
                                    <div className="flipcard-front light">
                                        <img
                                            src={slide.frontImage}
                                            alt={slide.frontAlt}
                                            className="quality-fdi-visual"
                                            sizes="(max-width: 479px) 82vw, (max-width: 767px) 80vw, 500px"
                                        />
                                        <div className="number-display turqoise left">{slide.frontNumber}</div>
                                        <div
                                            className="flipcard-more"
                                            onClick={() => handleFlip(slide.id)}
                                        >
                                            <span className="fa-solid-span dark"></span>
                                        </div>
                                        <h3 className="qfdi-card-heading">
                                            <strong>{slide.frontTitle}</strong>
                                        </h3>
                                    </div>
                                    <div className="flipcard-fdi-target-back">
                                        <h2 className="h3-heading">
                                            <strong>{slide.backTitle}</strong>
                                        </h2>
                                        <div className="paragraph-large bold">{slide.backBoldText}</div>
                                        <div className="flipcard-less" onClick={() => handleFlip(slide.id)}>
                                            <span className="fa-solid-span dark"></span>
                                        </div>
                                        <img
                                            src={slide.backImage}
                                            alt={slide.frontAlt}
                                            className="flipping-card-image back"
                                            sizes="(max-width: 479px) 82vw, (max-width: 767px) 80vw, 500px"
                                        />
                                        {/* <div className="paragraph-regular">{slide.backRegularText}</div> */}
                                        <span
                                            onClick={() => {
                                                setSelectedItem(slide);
                                                setShowContentModal(true);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                            className="tagline-heading"
                                        >Read More</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    className="generic-slider-trigger left cream w-slider-arrow-left"
                    onClick={handlePrevSlide}
                    aria-hidden={currentSlide === 0 ? "true" : "false"}
                >
                    <div className="slider-chevron"></div>
                </div>
                <div
                    className="generic-slider-trigger right cream w-slider-arrow-right"
                    onClick={handleNextSlide}
                    aria-hidden={currentSlide === totalSlides - 1 ? "true" : "false"}
                >
                    <div className="slider-chevron"></div>
                </div>
                <div className="slide-nav-hidden w-slider-nav w-round w-num"></div>
            </div>
            {selectedItem && <ContentModal isOpen={showContentModal} onClose={() => { setShowContentModal(false) }}>
                <Timeline description={selectedItem.backRegularText} items={selectedItem.items} />
            </ContentModal>}
        </section>
    );
};

export default QualityFdiSlider;
