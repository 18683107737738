import { NextSeo } from 'next-seo'
import { MainLayout } from '@/components/layout/MainLayout'
import HeroSection from '@/components/sections/HeroSection'
import { useContent } from '../../../hooks/useContent';
import PolicyAreasSection from '@/components/sections/PolicyAreasSection';
import QualityFdiSlider from '@/components/sliders/QualityFdiSlider';
import QualityFdiSection from '@/components/sections/QualityFdiSection';
import InsightsSection from '@/components/sections/InsightsSection';

export const HomePage: React.FC = () => {
   const { content } = useContent();

  return (
    <>
      <NextSeo
        title={'Türkiye’s FDI Strategy | Investment Office Türkiye'}
        description={
          'Discover Türkiye’s FDI Strategy with the Investment Office of Türkiye. Explore key investment opportunities, strategic insights, and the latest updates to support your business growth in Türkiye.'
        }
        twitter={{
          cardType: 'summary_large_image',
        }}
        openGraph={{
          title: 'Türkiye’s FDI Strategy | Investment Office Türkiye',
          description:
            'Discover Türkiye’s FDI Strategy with the Investment Office of Türkiye. Explore key investment opportunities, strategic insights, and the latest updates to support your business growth in Türkiye.',
          images: [
            {
              url: `https://uploads-ssl.webflow.com/666f68e852029a839d204e3c/66c1a963eeae257ddfa3fb86_FDI%20Strategy%20Design%20Guideline%20Visualized%20EN%20Reduced.avif`,
              width: 1024,
              height: 1024,
              type: 'image/jpeg',
            },
          ],
        }}
      />
      <MainLayout>
        <>
          <HeroSection
            heroData={content.heroSection}
            stickySections={content.goals}
          />
          <PolicyAreasSection
            cards={content.policyAreasSection.cards}
            tagline={content.policyAreasSection.tagline}
            heading={content.policyAreasSection.heading}
          />
          <QualityFdiSlider
            slides={content.qualityFdiSlider.slides}
            tagline={content.qualityFdiSlider.tagline}
            heading={content.qualityFdiSlider.heading}
          />

          <QualityFdiSection
            mockupImage={content.qualityFdiSection.mockupImage}
            mockupAlt={content.qualityFdiSection.mockupAlt}
            pdfLink={content.qualityFdiSection.pdfLink}
            pdfLabel={content.qualityFdiSection.pdfLabel}
            tagline={content.qualityFdiSection.tagline}
            heading={content.qualityFdiSection.heading}
            features={content.qualityFdiSection.features}
          />

          <InsightsSection
            tagline={content.insightsSection.tagline}
            heading={content.insightsSection.heading}
            tabs={content.insightsSection.tabs}
          />
        </>
      </MainLayout>
    </>
  )
}
