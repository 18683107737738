import React from 'react';

interface TimelineProps {
  description: string;
  items: string[];
}

const Timeline: React.FC<TimelineProps> = ({ description, items }) => {
  return (
    <div className="timeline-container">
      <h3 className="timeline-description">{description}</h3>
      <ul className="timeline-list">
        {items.map((item, index) => (
          <li key={index} className="timeline-item">
            <p className="timeline-text">{item}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Timeline;
