import React from 'react';
import SubscriberLink from '../forms/SubscriberLink';

interface FeatureData {
    id: string;
    className: string;
    icon: string;
    title: string;
    description: string;
}

interface QualityFdiSectionProps {
    mockupImage: string;
    mockupAlt: string;
    pdfLink: string;
    pdfLabel: string;
    tagline: string;
    heading: string;
    features: FeatureData[];
}

const QualityFdiSection: React.FC<QualityFdiSectionProps> = ({
    mockupImage,
    mockupAlt,
    pdfLink,
    pdfLabel,
    tagline,
    heading,
    features
}) => {
    return (
        <section className="section-large dark-blue">
            <div className="container-large">
                <div className="content-main-wrap">
                    {/* Mockup Holder */}
                    <div id="w-node-f31e102f-d8e9-e5f8-3a85-91facd5c1cd9-951b493d" className="mockup-holder">
                        <img
                            src={mockupImage}
                            loading="lazy"
                            alt={mockupAlt}
                            sizes="(max-width: 479px) 320px, (max-width: 991px) 300px, 23vw"
                            className="mockup-image"
                        />
                    </div>

                    {/* Content Mockup Wrap */}
                    <div id='w-node-aa06b421-a47c-8074-6cb5-f60b951b4953-951b493d' className="content-mockup-wrap">
                        <div className="middle-heading-set">
                            <div className="tagline-heading">{tagline}</div>
                            <div className="heading-set-3">
                                <h2 className="h3-heading middle light" 
                                  dangerouslySetInnerHTML={{ __html: heading }}
                                ></h2>
                            </div>
                        </div>
                        <SubscriberLink href={pdfLink} target="_blank" className="button-left-icon w-inline-block">
                            <div className="button-icon-left"></div>
                            <div className="text-block-4">{pdfLabel}</div>
                        </SubscriberLink>
                        <div className="mockup-background middle-bg"></div>
                        <div className="mockup-background native"></div>
                    </div>

                    {/* Features Section */}
                    {features.map((feature, index) => (
                        <div className={feature.className} id={feature.id} key={`${feature.id}${index}`}>
                            <div className="content-feature-item">
                                <div className="icon-wrap no-shadow dark">
                                    <div className="fa-solid-span turqoise _48px">{feature.icon}</div>
                                </div>
                                <div className="wrap-v-tiny">
                                    <div className="h4-heading light">{feature.title}</div>
                                    <p className="paragraph-x-small full-white hide">{feature.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default QualityFdiSection;
