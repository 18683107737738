import React, { useEffect, type ReactNode } from 'react';

interface ContentModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
}

const ContentModal: React.FC<ContentModalProps> = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        // Cleanup to remove class when component unmounts
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div data-overlay="active" className="rf-digitalmat-overlay">
            <div
                data-core-overlay-content="modal"
                className="rf-digitalmat-overlay-content"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    data-core-overlay-close
                    className="rf-digitalmat-overlay-close"
                    onClick={onClose}
                    aria-label="Close Modal"
                >
                    &times;
                </button>
                {/* <div   onClick={onClose}
                    aria-label="Close Modal" className="modal-close-button"><div className="x-button"></div></div> */}
                <div className="rf-digitalmat-overlay-contentsection">
                    <div className="rf-digitalmat-overlay-header">
                        {/* <h2>{contentData.title}</h2> */}
                    </div>
                    <div className="rf-digitalmat-gallery">
                        <div className="rf-digitalmat-gallery-items">
                            {children}
                        </div>
                    </div>
                </div>
                <div className="rf-digitalmat-overlay-footer">
                    <p></p>
                </div>
            </div>
        </div>
    );
};

export default ContentModal;
